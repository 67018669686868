label[required]::after {
  content: '*';
  margin-left: 5px;
  color: red;
}

.main .container-fluid:has(#adminV2) {
  padding: 0;
}

.min-vh-90 {
  min-height: 90vh !important;
}
