// Here you can add other styles
.loading-mask-container {
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: center;
  top: 0px;
  left: 0px;
  display: none;
  z-index: 9000;

  &.show {
    display: block;
  }

  .mask {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    position: absolute;
    z-index: 9999;
  }

  .loading-mask {
    height: 60px;
    margin: calc(50vh - 30px) auto auto auto;
    width: 100px;
    font-size: 10px;
    z-index: 1500;

    div {
      background-color: #20a8d8;
      height: 100%;
      width: 6px;
      display: inline-block;
      -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
      animation: stretchdelay 1.2s infinite ease-in-out;

      &:not(:last-child) {
        margin-right: 3px;
      }
    }

    .rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }

    .rect3 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    .rect4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .rect5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }
}

@keyframes stretchdelay {
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  0%,
  100%,
  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}

@-webkit-keyframes stretchdelay {
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  0%,
  100%,
  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}

.hide-input-file {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.hide-input-button {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.draganddrop {
  display: inline-block;
  border-top: 2px solid #ccc;
  width: 25px;
  padding-top: 2px;
  margin-top: 10px;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.draganddrop:before {
  display: block;
  content: "";
  border-top: 2px solid #ccc;
  padding-top: 2px;
}

.draganddrop:after {
  display: block;
  content: "";
  border-top: 2px solid #ccc;
  padding-top: 2px;
}

.draganddrop:hover,
.draganddrop:hover:before,
.draganddrop:hover:after {
  border-color: #57b5d6;
}

.draganddrop:active,
.draganddrop:active:before,
.draganddrop:active:after {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.sortableHelper {
    z-index: 10000;
}

.react-datetimerange-picker,
.react-daterange-picker,
.react-timerange-picker,
.react-datetime-picker,
{
    @extend .form-control
}

.react-datetimerange-picker__wrapper,
.react-daterange-picker__wrapper,
.react-timerange-picker__wrapper,
.react-datetime-picker__wrapper,
{
    border:thin !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}

